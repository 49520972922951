.creationModal {
  padding: 2% 5% 2% 5%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #9ca2cf;
  background-image: linear-gradient(to bottom, #3b437d, #1f265e);
  border-radius: 18px;
  width: 570px;
  height: auto;
  z-index: 55555;

  .close-button {
    position: absolute;
    color: #656fb5;
    padding: 10px;
    right: 0;
    top: 0;
    border: 0;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
  }
  .title {
    position: absolute;
    left: 0;
    top: 3%;
    padding-left: 40px;
  }
}

.ordersModal {
  padding: 2% 1% 2% 1%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #9ca2cf;
  background-image: linear-gradient(to bottom, #3b437d, #1f265e);
  border-radius: 18px;
  width: 900px;
  height: auto;
  max-height: 600px;
  overflow: auto;
  z-index: 55555;

  .close-button {
    position: absolute;
    color: #656fb5;
    padding: 10px;
    right: 0;
    top: 0;
    border: 0;
    background: transparent;
    font-size: 2rem;
    cursor: pointer;
  }
  .title {
    position: absolute;
    left: 0;
    top: 3%;
    padding-left: 40px;
  }
}
[data-reach-dialog-overlay] {
  background: hsla(0, 0%, 0%, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  z-index: 4444;
}
